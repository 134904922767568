import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getNumberFormatted } from "../../utils/stringUtils";
import isEmpty from "../../actions/isEmpty";
import uuid from "uuid";

const getProductStatus = (status, pedidos = null) => {
  let productStatus = null;
  if (isEmpty(status)) {
    return productStatus;
  }
  if (isEmpty(pedidos)) {
    pedidos = [];
  }
  switch (status) {
    case "ST_INACTIVE":
      productStatus = (
        <span className="d-block">
          Estatus: Inactivo
          <div
            className="circle-element red right bordered"
            style={{ width: "20px", height: "20px" }}
          />
        </span>
      );
      break;

    case "ST_ORDERED":
      productStatus = (
        <React.Fragment>
          <span className="d-block">
            Estatus: Pendiente de entrega
            <div
              className="circle-element amber right bordered"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
          <span className="d-block bordered p-1">
            {pedidos.map((pedido) => getContentPedido(pedido))}
          </span>
        </React.Fragment>
      );
      break;
    default:
      break;
  }

  return productStatus;
};

const getContentPedido = (pedido) => {
  const { proveedor, local_solicitado, cantidad } = pedido;
  return (
    <span className="d-block border-bottom" key={uuid()}>
      {`${cantidad} de `}
      {proveedor && ` Proveedor: ${proveedor.nombre}`}
      {local_solicitado && ` Local: ${local_solicitado.nombre}`}
    </span>
  );
};

const getProductoUbicacion = (params = {}) => {
  const { ubicacion, distribucion, nombre, codigo_barra, admin } = params
  let productoUbicacion = null;

  if (isEmpty(ubicacion) && isEmpty(distribucion)) {
    return productoUbicacion;
  }
  if (!isEmpty(distribucion) && Array.isArray(distribucion)) {
    productoUbicacion = (
      <table className="table-bordered mt-1 table-bold">
        <thead>
          <tr>
            {admin === true && (
              <th></th>
            )}
            <th>Local</th>
            <th>Ubicacion</th>
            <th>Cantidad</th>
          </tr>
        </thead>

        {distribucion.length > 0 && (
          <tbody>
            {distribucion.map((dist) => (
              <tr key={uuid()}>
                {admin === true && (
                  <td>
                    <span className="d-block">
                      <a
                        href={`whatsapp://send?text=${nombre}%0a %0aCodigo: ${codigo_barra}%0aCantidad: ${dist.existencia} %0aUbicacion: ${dist.ubicacion}%0a %0aLocal: ${dist.local.nombre}`}
                      >
                        <img
                          src="http://icons.iconarchive.com/icons/dtafalonso/android-l/512/WhatsApp-icon.png"
                          alt=""
                          width="20px"
                          height="20px"
                        />
                      </a>
                    </span>
                  </td>
                )}
                <td>{dist.local && dist.local.nombre}</td>
                <td>{dist.ubicacion}</td>
                <td>{dist.existencia}</td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    );
  } else if (!isEmpty(ubicacion)) {
    productoUbicacion = <span className="d-block">Ubicacion: {ubicacion}</span>;
  }
  return productoUbicacion;
};

const ProductCard = (props) => {
  const {
    product: {
      id,
      nombre,
      precio,
      existencia,
      codigo_barra,
      imagen,
      marca,
      tipo_vehiculo,
      ubicacion,
      distribucion,
      status,
      pedidos,
      existencia_global,
      congelado,
      ultimas_ventas,
      last_prices,
    },
    admin,
    noLink,
    addButton,
    onAddClick,
  } = props;
  let productImage;
  let productTitle;
  if (noLink) {
    productImage = (
      <Link to={`${admin ? "/admin" : ""}/productos/${id}`}>
        {imagen && imagen.url ? (
          <img src={imagen.url} alt="" />
        ) : (
          <img src="" alt="" />
        )}
      </Link>
    );
    productTitle = (
      <Link to={`${admin ? "/admin" : ""}/productos/${id}`}>
        <h6 className="d-block">{nombre}</h6>
      </Link>
    );
  } else {
    productImage =
      imagen && imagen.url ? (
        <img src={imagen.url} alt="" />
      ) : (
        <img src="" alt="" />
      );
    productTitle = <h6 className="d-block">{nombre}</h6>;
  }
  let productContent = (
    <React.Fragment>
      <div className="card-image border-bottom card-product">
        {productImage}
      </div>
      <div className="card-content">
        {productTitle}
        {codigo_barra && <span>Codigo de barra: {codigo_barra}</span>}
        <span className="d-block">
          Precio: Lps <span>{getNumberFormatted(precio)}</span>
        </span>
        {marca && <span className="d-block">Marca: {marca.nombre}</span>}
        {tipo_vehiculo && (
          <span className="d-block">
            Tipo de vehiculo: {tipo_vehiculo.nombre}
          </span>
        )}
        <span className="d-block">En Inventario: {existencia}</span>
        {existencia_global && (
          <span className="d-block">
            Inventario en otras tiendas: {existencia_global}
          </span>
        )}
        {ultimas_ventas && (
          <span className="d-block">
            Ventas en los ultimos 30 dias: {ultimas_ventas}
          </span>
        )}
        {last_prices && (
          <span className="d-block">Ultimos precios: {last_prices}</span>
        )}
        {getProductStatus(status, pedidos)}
        {getProductoUbicacion({
          nombre,
          codigo_barra,
          existencia,
          ubicacion,
          distribucion, 
          admin
        })}
        {(admin == false && isEmpty(distribucion)) && (
          <span className="d-block">
            <a
              href={`whatsapp://send?text=${nombre}%0a %0aCodigo: ${codigo_barra}%0aCantidad: ${existencia} %0aUbicacion: ${ubicacion}`}
            >
              <img
                src="http://icons.iconarchive.com/icons/dtafalonso/android-l/512/WhatsApp-icon.png"
                alt=""
                width="30px"
                height="30px"
              />
            </a>
          </span>
        )}
        {congelado === true && (
          <span className="d-block">
            Producto congelado
            <div
              className="circle-element blue bordered right"
              style={{ width: "20px", height: "20px" }}
            />
          </span>
        )}
        {addButton && (
          <button
            className="btn btn-block"
            style={{ marginTop: "10px" }}
            onClick={() => {
              onAddClick(props.product);
            }}
          >
            Agregar
          </button>
        )}
      </div>
    </React.Fragment>
  );
  return (
    <div className="card hoverable">
      {noLink ? (
        productContent
      ) : (
        <Link to={`${admin ? "/admin" : ""}/productos/${id}`}>
          {productContent}
        </Link>
      )}
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  noLink: PropTypes.bool.isRequired,
  addButton: PropTypes.bool.isRequired,
  onAddClick: PropTypes.func,
};

ProductCard.defaultProps = {
  admin: false,
  noLink: false,
  addButton: false,
  onAddClick: () => {},
};

export default ProductCard;
